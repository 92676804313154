import tapitService from "../services/tapitService.js";
import dama from "../services/dama.js";
import Api from "../services/api";
import jwt from '../services/jwt'
import { setRedirects } from './functions'

const dm = new dama(),
  api = new Api(),
  ts = new tapitService();

const onFlowCompletedProcess = localStorage.getItem("onFlowCompletedProcess") == "true"

let routes = [],
  defaultRoute = [],
  ctmp,
  CONFIGURATION = {},
  CONFIG_OBJECT_TAPIT = {},
  DISABLED_REDIRECT = false,
  DISABLED = false,
  CONFIG_OBJECT_PROJECT = {};

const endpointLogin = "/login_tapit150/auth/login";
const endpointLogout = "/login_tapit150/auth/logout";

window.getUserTapit = () => { };
// window.tapitEvents.init = () => {}

export const page = async () => {
  const routesTapit = await api.routesTapit();
  // Si llega algun error de las rutas, terminamos el proceso de carga
  if (!routesTapit) {
    console.log("%cTapit no inicio", "color: red; font-style: italic; text-decoration: underline; text-transform: uppercase;");
    dm.hideLoader();
    return;
  }
  (routes = routesTapit.routes), (defaultRoute = routesTapit.default), init();
};

//@INIT configuratio get
function init() {
  console.log('init tapit')
  CONFIGURATION = routes.find((route) =>
    route.path.find((path) => path == location.pathname)
  );

  //Si la ruta no se encuentra, vamos a configurar la ruta por defecto es decir defaultRoute
  CONFIGURATION = CONFIGURATION || defaultRoute;

  //Configuramos Tapit
  let config = CONFIGURATION.config;
  CONFIG_OBJECT_PROJECT = config ? config : {};
  CONFIG_OBJECT_TAPIT = CONFIG_OBJECT_PROJECT;
  DISABLED = CONFIGURATION.disabled ? CONFIGURATION.disabled : false;
  localStorage.setItem("configTapit", JSON.stringify(CONFIG_OBJECT_TAPIT));

  window.CONFIG_OBJECT_TAPIT = CONFIG_OBJECT_TAPIT ? CONFIG_OBJECT_TAPIT : {};
  window.CONFIG_OBJECT_GLOBAL_TAPIT = CONFIGURATION ? CONFIGURATION : {};
  ctmp = CONFIG_OBJECT_TAPIT;
  renderGlobalFunction();
}

async function renderGlobalFunction() {
  if (CONFIG_OBJECT_GLOBAL_TAPIT) {
    console.log('Agregando estilos al sitio');
    const s = document.createElement('style');
    if (localStorage.tapitStyle) {
      s.innerHTML = localStorage.tapitStyle;
      localStorage.removeItem('tapitStyle')
    }
    else {
      s.innerHTML = CONFIG_OBJECT_GLOBAL_TAPIT?.config?.styles ?? '';
    }
    document.querySelector('body').append(s);
    console.log('Agregados');
  }
  // IF USER DISABLED THE FUNCIONALLITY, SO DONT WORK THE NEXT FUNCTIONS
  if (!DISABLED) {
    window.getUserTapit = () => {
      return new Promise(async (resolve, reject) => {
        const user = await ts.getUser();
        const request = user
          ? { status: true, user: user, message: "User Found" }
          : { status: false, message: "User Not Found" };
        //If user login in drupal but dont in tapit, we need close session
        resolve(request);
      });
    };

    window.initSession = (data = {}, redirects = { redirectNewUser: '', redirect: '' }) => {
      CONFIG_OBJECT_TAPIT = data.project
        ? { ...CONFIG_OBJECT_TAPIT, ...data }
        : ctmp;
      ssoApp.configApp(CONFIG_OBJECT_TAPIT);
      typeof ssoApp != undefined && ssoApp.showApp();
      console.log('iniciar session');
      // If not set redirects in the functions args, then set the redirect based on CONFIGURATION json
      localStorage.removeItem('_ltrnu');
      localStorage.removeItem('_ltru')
      if (redirects.redirectNewUser == '') {
        redirects.redirectNewUser = CONFIGURATION.redirectNewUser;
      }
      if (redirects.redirect == '') {
        redirects.redirect = CONFIGURATION.redirectLogin;
      }
      localStorage.tapitStyle = CONFIGURATION?.config?.styles ?? '';
      setRedirects(redirects);
      console.log(redirects);
    };

    window.closeSession = () => {
      localStorage.removeItem('onFlowCompleted')
      localStorage.removeItem('onFlowCompletedProcess')
      localStorage.removeItem('firstLogin')
      localStorage.removeItem('customToken')
      localStorage.removeItem('userStatus')
      typeof ssoApp != undefined && ssoApp.logout();
      location = `${endpointLogout}`;
    };

    window.myProfile = () => {
      location = CONFIGURATION.myProfile ? CONFIGURATION.myProfile : "/";
    };
    window.firstLogin = async (userCredential, user, token) => {
      var evt = new CustomEvent("tapitUserInitSession", {
        detail: {
          email: user.email,
          campaign: CONFIGURATION.name,
        },
      });
      window.dispatchEvent(evt);

      //Send endpoint extra
      if (CONFIGURATION.apiSendUser) {
        let dataApi = {
          userCredential,
          user,
        };
        const request = await api.sendUserApi(
          CONFIGURATION.apiSendUser,
          dataApi
        );
      }

      // if(!DISABLED_REDIRECT){
      const dataToSend = {
        uid: user.uid,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        avatar: user.userPhoto,
        gender: user.gender,
        identityType: user.identityType,
        identity: user.identity,
        city: user.city,
        phone: user.phone,
        birthday: user.birthDate.seconds,
        points: user.points,
        referralUrl: user.referralUrl,
        redirect: localStorage._ltru ? localStorage._ltru : CONFIGURATION.redirectLogin,
        redirectNewUser: localStorage._ltrnu ? localStorage._ltrnu : CONFIGURATION.redirectNewUser,
        origin: CONFIGURATION.config.origin,
      }
      const dataJWT = jwt(dataToSend)
      console.log(dataJWT, dataToSend);
      let endPointRegister = `${endpointLogin}?jwt=${dataJWT}`;

      localStorage.setItem("onFlowCompletedProcess", false);

      //We verify if user exist, depend from status we activated the status(register or login)
      const request = await api.userExistInDrupal(dataJWT)
      localStorage.setItem("userStatus", (request.exist) ? "login" : "register")

      location.href = endPointRegister;
      // }
    };
  }

  window.login_tapit_credits = 'Github: davidfmb93'

  let userTapit = await ts.getUser();
  if (onFlowCompletedProcess) {
    //localStorage.removeItem("onFlowCompletedProcess");
    //location.reload();
    console.warn('Espera!!! el usuario se encuentra iniciando sesión...')
    await window.getUserTapit(); // try it.
  } else {
    lunchEvents(userTapit);
  }


}

function lunchEvents(userTapit) {

  // document.addEventListener("tapitLoad", (e) => {
  //   console.log('Example Tapit Load')
  //   const { user, isLogout, userTapit,  newUser} = e.detail
  //   console.log('user::',user);
  //   console.log('isLogout::',isLogout);
  //   console.log('userTapit::',userTapit);
  //   console.log('newUser::',newUser);
  // })
  const ImLoggedInDrupal = drupalSettings.user.uid > 0
  const ImLoggedInTapit = !!(userTapit)

  const isLogout = !(ImLoggedInDrupal && ImLoggedInTapit)

  const detail = { user: userTapit, isLogout }
  const detailMerged = { ...detail, ...userStatusEvent() }

  const evt = new CustomEvent("tapitLoad", {
    detail: detailMerged,
  });
  document.dispatchEvent(evt);

  userStatusEvent()

  dm.hideLoader();
  console.log("%cTapit ha sido cargado", "color: #87D2FE; font-style: italic; text-decoration: underline; text-transform: uppercase;");
}


function userStatusEvent() {
  const userStatus = localStorage.userStatus
  if (userStatus) {
    localStorage.removeItem('userStatus')
    return {
      newUser: (userStatus == 'register') ? true : false
    }
  }
  return {}
}
