
/**
 * 
 * Service JS
 * 
 * 
 */

 class dama {
  click(name = null, configuration) {
      const element = document.querySelectorAll('[d-click='+name+']')[0]
      element && element.addEventListener("click", configuration, false)
      return 'render';
  }
  hide(ids = []){
      ids.map(id => {
          const element = document.getElementById(id)
          if(element) element.style.display = "none";
      })
      return 'render';
  }
  show(id = null){
      const element = document.getElementById(id)
      if(element) element.style.display = "block";
      return 'render';
  }
  showByClass(nameClass = null){
      const elements = document.getElementsByClassName(nameClass)
      if(elements){
          Array.prototype.forEach.call(elements, (element) => {
              if(element) element.style.display = "block";
          });
      }

      return 'render';
  }

  hideByClass(nameClass = null){
      const elements = document.getElementsByClassName(nameClass)
      if(elements){
          Array.prototype.forEach.call(elements, (element) => {
              if(element) element.style.display = "hide";
          });
      }

      return 'render';
  }

  hideShow(idHide = null, idShow = null){
      let element = document.getElementById(idHide)
      if(element) element.setAttribute("style","display:none !important");
      element = document.getElementById(idShow)
      if(element) element.setAttribute("style","display:block !important");
      return 'render';
  }

  loader(){
    let html = `<!-- LOADER_tapit -->
    <div class="loader-tapit">
      <div class="loader_tapit__container">
        <div class="heart">
          <img src="https://bbc.dp150.xyz/themes/custom/co_bbc/co_bbc/src/dist/img/LOGO-BBC-NEGATIVO%202.png">
        </div>
      </div>
    </div>
    <!-- END LOADER_tapit --`
    
    document.body.insertAdjacentHTML('beforeend',html);
    setTimeout(() => { document.querySelector('.loader-tapit') && document.querySelector('.loader-tapit').classList.add('loader-tapit-hide') }, 8000)
  }
  hideLoader(){
    document.querySelector('.loader-tapit') && document.querySelector('.loader-tapit').classList.add('loader-tapit-hide')
  }
  hideLoaderTime(time){
    setTimeout(() => { document.querySelector('.loader-tapit') && document.querySelector('.loader-tapit').classList.add('loader-tapit-hide') }, time*1000)
  }
}


export default dama;