// Import Crypto js
import CryptoJS from 'crypto-js'



window.jwt = ( data ) => {


	// Encrypt Base 64
	function base64Url( source ) {
		// Encode in classical base64
		let encodedSource = CryptoJS.enc.Base64.stringify( source )
		// Remove padding equal characters
		encodedSource = encodedSource.replace(/=+$/, '')
		// Replace characters according to base64url specifications
		encodedSource = encodedSource.replace(/\+/g, '-')
		encodedSource = encodedSource.replace(/\//g, '_')
		return encodedSource
	}

	function stringifyEncrypt( dataToEncrypt ) {
		const stringifiedData = CryptoJS.enc.Utf8.parse( JSON.stringify( dataToEncrypt ) )
		const encodedData = base64Url( stringifiedData )

		return encodedData
	}

	// Encrypt header and data for the JWT
	const header = {
		'alg': 'HS256',
		'typ': 'JWT'
	}

	// Final token
	const token = `${stringifyEncrypt(header)}.${stringifyEncrypt(data)}`

	// Token signed
	// const secret = '150porciento'
	// const signature = CryptoJS.HmacSHA256(token, secret)
	// signature = base64url(signature)
	// const signedToken = `${token}.${signature}`
	const signedToken = token

	return signedToken

}



export default jwt
