import { async } from "regenerator-runtime";

class apiDrupal {

  async routesTapit(){
    try {
      return await get(drupalSettings.login_tapit.config)
    } catch (error) {
      console.warn('Cargar por favor de manera correcta el archivo de configuración de tapit')
      console.warn(`Actualmente se encuentra en: ${drupalSettings.login_tapit.config}`)
      return false;
    }
  }

  async userExistInDrupal(token){
    try {
      return await get(`/login_tapit150/auth/get-user?jwt=${token}`)
    } catch (error) {
      console.warn('Error al verificar el estado del usuario')
      return {};
    }
  }

  async sendUserApi(endpoint, data) {
    try {
      const request = await post(endpoint, data);
      return request;
    } catch (error) {
      return {};
    }
  }
}

export default apiDrupal;

function get(url) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch(error => reject('Error:', error))

  });
}

function post(url, data) {
  try {
    data = {
      user : data.user,
      userCredential :data.userCredential
    }
    return new Promise(async (resolve, reject) => {
      const rawResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data, getCircularReplacer())
      });
      const response = rawResponse ? rawResponse.json() : {};
      resolve(response)
    }).catch(error =>{
      return {}
    });
  } catch (error) {
    return {}
  }

}

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
