// import middleware  from './services/middleware.js'
// import { events } from "./partials/events"
// import tapitService from './services/tapitService.js';
import { page } from './partials/generalPage'
import dama from "./services/dama.js";
import axios from 'axios';
const dm = new dama();
// const mw = new middleware()
function render() {
    let count = 0;
    const max_sec = drupalSettings.login_tapit.hideloadersec;
    const interval = setInterval(() => {
        if (typeof window.ssoApp !== "undefined") {
            console.log(`Tapit ha respondido: ${drupalSettings.login_tapit.enviorment}`);
            const formData = new FormData();
            formData.append('error', false);
            formData.append('segundos', count);
            formData.append('acción', 'Tapit cargo correctamente');
            formData.append('extra1', `typeof window.ssoApp: ${typeof window.ssoApp}`);
            formData.append('extra2', `drupalSettings.login_tapit.hideloadersec: ${max_sec}`);
            var r = new XMLHttpRequest();
            r.open('POST', '/login_tapit150/save/load', true);
            r.send(formData);
            dm.hideLoaderTime(1) //seconds respond hide automatically
            clearInterval(interval);
            init()
            clearInterval(interval)
        } else {
            count++
            console.log('Esperando que tapit responda', max_sec-count)
            const formData = new FormData();
            if (count % 10 == 0) {
                console.log('%cTapit sigue sin contestar', "color: #FFCC00;");
                formData.append('error', false);
                formData.append('segundos', count);
                formData.append('acción', 'No se ha cargado tapit aún');
                formData.append('extra1', `typeof window.ssoApp: ${typeof window.ssoApp}`);
                formData.append('extra2', `drupalSettings.login_tapit.hideloadersec: ${max_sec}`);
                var r = new XMLHttpRequest();
                r.open('POST', '/login_tapit150/save/load', true);
                r.send(formData);
            }
            if(count >= max_sec) {
                console.log('%cTapit no respondio', "color: #FF0000;");
                formData.append('error', true);
                formData.append('segundos', count);
                formData.append('acción', 'Intentar cargar window.ssoApp');
                formData.append('extra1', `typeof window.ssoApp: ${typeof window.ssoApp}`);
                formData.append('extra2', `drupalSettings.login_tapit.hideloadersec: ${max_sec}`);
                var r = new XMLHttpRequest();
                r.open('POST', '/login_tapit150/save/load', true);
                r.send(formData);
                dm.hideLoaderTime(1) //seconds respond hide automatically
                clearInterval(interval);
            }
        }
    }, 1000);
}
render()


function init() {
    console.log("%cTapit esta siendo iniciado", "color: #75ECC2; font-style: italic; text-decoration: underline;text-transform: uppercase;");
    ssoApp.onFlowCompleted().subscribe(async res => {
        console.log('onFlowCompleted del init');
        subscribeProcess();
    });
    if(location.pathname.includes('doble-malta') && !localStorage.hasOwnProperty('onFlowCompletedProcess'))
    {
        //console.log('set onFlowCompletedProcess');
        //subscribeProcess();
    }
    page()
}

window.subscribeProcess = async function()
{
    localStorage.setItem('onFlowCompleted', true)
    localStorage.setItem('onFlowCompletedProcess', true)
    if (typeof (window.getUserTapit) == 'function') {
        await window.getUserTapit()
    } else {
        location.reload()
    }
}
document.addEventListener('tapitLoad', function (e) {
    ssoApp.onFlowCompleted().subscribe(function (res) {
        console.log('onFlowCompleted del customtoken')
        if (res.userCredential) {
            ssoApp.getCustomToken(res.userCredential).subscribe(function (customToken) {
                localStorage.setItem('customToken', customToken);
            });
        }
    });

    // onAuthStateChanged is callen when the auth status of the user si cahnging
    ssoApp.auth.onAuthStateChanged(function (userCredential) {
        console.log('onAuthStateChanged..')
        console.log(userCredential)
        if (userCredential) {
            console.log('inside if')
            ssoApp.getCustomToken(userCredential).subscribe(function (customToken) {
                console.log('getCustomToken has been called')
                localStorage.setItem('customToken', customToken);
            });
            // Check if loggedCustomToken is true after the signInWithCustomToken
            if (window.loggedCustomToken === true) {
                loginInDrupal(userCredential);
            }
        }
    });
    // Verify if the url params have the customToken
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('customToken')) {
        // IF the user isn't logged in drupal, sign in with the custom token from url
        console.log("receive custom token to login")
        axios.post('/login_tapit150/auth/check-user')
            .then((data) => {
            })
            .catch(error => {
                if (error.response.status == 401) { // Not logged
                    console.log("url endpoint response 401");
                    checkUserInTapit(urlParams.get('customToken'))
                }
            });
    }

}, false);

const checkUserInTapit = async (token) => {
    console.log('checkUserInTapit')
    const checkTapit = await getUserTapit();
    ssoApp.auth.signInWithCustomToken(token)
        .then(async (userCredential) => {
            console.log("signInWithCustomToken");
            // Signed in in tapit
            var user = userCredential.user;
            if (checkTapit.status) {
                console.log("logged in tapit before")
                //loginInDrupal(userCredential);
                ssoApp.auth.signOut().then(() => {
                    console.log("sign Out, recheck");
                    checkUserInTapit(token)
                }).catch((error) => {
                    console.log("error signOut", error)
                });
            }
            else {
                window.loggedCustomToken = true; // After this, is called the onAuthStateChanged
            }
            // ...
        })
        .catch((error) => {
            console.log("error urlParams", error)
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
        });
}

const loginInDrupal = (userCredential) => {
    console.log('loginInDrupal')
    const uid = userCredential.uid;
    ssoApp.firestore
        .collection("user_account_tap")
        .doc(userCredential.uid)
        .get()
        .then(async (document) => {
            let token = null;
            document.data().token = token;
            let request = await document.data();
            request["token"] = token;
            request["uid"] = uid;
            console.log("firstlogin")
            window.firstLogin(userCredential, request, token) // Signed id in drupal too
        })
        .catch(function (error) {
            console.log('error firestore', error);
        });
}

/*
*
* CODE DISABLED

function init(){
    require('./partials/generalPage.js').page();
}

THIS CODE inside page
    window.configTapitSso = () => {
        if(flag){
            console.warn('Tapit ha respondido')
            init()
            flag = false
            ssoApp.onFlowCompleted().subscribe(async res => {
                localStorage.setItem('onFlowCompleted', true)
                localStorage.setItem('onFlowCompletedProcess', true)
                if(typeof(window.getUserTapit) == 'function'){
                    await window.getUserTapit()
                }else{
                    location.reload()
                }
            });
        }
    }
*/
