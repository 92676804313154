class tapitService {
  getUser() {
    return new Promise((resolve, reject) => {
      ssoApp.auth.onAuthStateChanged((userCredential) => {
        if (userCredential) {
          const uid = userCredential.uid;
          ssoApp.firestore
            .collection("user_account_tap")
            .doc(userCredential.uid)
            .get()
            .then(async (document) => {
              let token = await getToken();
              document.data().token = token;
              let request = await document.data();
              request["token"] = token;
              request["uid"] = uid;
              //Primero que todo el firstLogin debe ser realizado unica vez o sino la peticion sera cancelada
              //Luefo, si hemos sido logeados en firebase pero en nuestra plataforma no, verificamos desde nuestro lado, si este esta en 0, significa que esta deslogeado
              //tambien se integro esta variable en el localstorage, pero no se esta utilizando "tapit-is-login-process"
              //Si el usuario esta en proceso de autenticacion lo logeamos, si no entonces lo dejamos con la data corriente,  window.tapit_logging
              if(!localStorage.getItem('firstLogin')){
                // En clubcolombia al parecer no se ejecuta el ssoApp.onFlowCompleted().subscribe que setea el item onFlowCompletedProcess
                const onFlowCompletedProcess = (location.pathname.includes('doble-malta') && !localStorage.hasOwnProperty('onFlowCompletedProcess')) ? true : localStorage.getItem("onFlowCompletedProcess") == "true"
                console.log('Llegamos al firstlogin', localStorage.getItem("onFlowCompletedProcess"), drupalSettings.user.uid)
                if(onFlowCompletedProcess){
                  localStorage.setItem('firstLogin', true)
                }
                if ( drupalSettings.user.uid == 0 && onFlowCompletedProcess ) {
                  resolve(firstLogin(userCredential, request, token));
                  request = delelevariables(request);
                }
              }
              else{
                localStorage.removeItem('firstLogin')
              }

              resolve(request);
            })
            .catch(function (error) {
              reject(error);
            });
        } else {
          resolve(userCredential);
        }
      });
    });
  }
}

function getToken() {
  return new Promise((resolve) => {
    ssoApp.auth.currentUser.getIdToken().then(function (idToken) {
      resolve(idToken);
    });
  });
}

function delelevariables(request) {
  delete request.referralCode;
  return request;
}

export default tapitService;
